import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Navbar } from "reactstrap";
import "./HeaderNavigation.scss";

class HeaderNavigation extends Component {
  state = {
    menuOpened: false,
  };

  constructor(props) {
    super(props);

    this.onNavlinkClick = this.onNavlinkClick.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  onNavlinkClick(event) {
    this.setState({ menuOpened: false });
  }

  toggleMenu(event) {
    this.setState((state) => ({
      menuOpened: !state.menuOpened,
    }));
  }

  render() {
    const { menuOpened } = this.state;

    return (
      <Navbar expand="md" className="header-navbar">
        <Container>
          <Link to="/home-page" className="navbar-brand">
            <h2>Facenet</h2>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.toggleMenu}
          >
            <i className="fas fa-align-justify" />
          </button>

          <div
            className={`collapse navbar-collapse ${menuOpened ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <NavLink
                  className="nav-link"
                  activeClassName="active-page"
                  to="/home-page"
                  onClick={this.onNavlinkClick}
                >
                  Գլխավոր էջ
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active-page"
                  to="/pricing"
                  onClick={this.onNavlinkClick}
                >
                  Գնացուցակ
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active-page"
                  to="/contact-us"
                  onClick={this.onNavlinkClick}
                >
                  Հետադարձ կապ
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active-page"
                  to="/reporting"
                  onClick={this.onNavlinkClick}
                >
                  Հաշվետվություն
                </NavLink>
              </li>
            </ul>
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default HeaderNavigation;
