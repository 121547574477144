import React, { Component } from "react";
import { Container } from "reactstrap";
import "./JoinUsArea.scss";

class JoinUsArea extends Component {
  render() {
    return (
      <div className="join-us-area">
        <Container className="container-with-bg">
          Էլ ի՞նչ եք սպասում, միացեք մեզ:
        </Container>
      </div>
    );
  }
}

export default JoinUsArea;
