import React, { Component } from "react";
import "./ServiceItem.scss";

class ServiceItem extends Component {
  render() {
    const { type, title, text } = this.props;

    const icons = {
      user: "fas fa-users",
      price: "fas fa-hand-holding-usd",
      connect: "fas fa-plug",
      stable: "fas fa-thumbs-up",
      wifi: "fas fa-wifi",
    };

    return (
      <div className="service-item">
        <div className="service-item-icon">
          <i className={icons[type]}></i>
        </div>
        <div className="service-item-title">{title}</div>
        <div className="service-item-text">{text}</div>
      </div>
    );
  }
}

export default ServiceItem;
