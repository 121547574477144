import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import "./Reporting.scss";

import { BigTitle } from "../../components";

class Reporting extends Component {
  static pageClassName = "reporting";

  yearFrom = 2021;
  quartersFrom = 1;
  yearTo = 2999;
  quartersTo = 4;

  list = [];

  constructor(props) {
    super(props);

    const nowYear = new Date().getFullYear();
    const nowMonth = new Date().getMonth() + 1;

    this.yearTo = nowYear;
    this.quartersTo = 1 + Math.floor(nowMonth / 3);

    for (let year = this.yearFrom; year <= this.yearTo; ++year) {
      const annually = {
        year: year,
        quarters: [],
      };

      const append = (quarter) => {
        if (annually.quarters.includes(quarter)) {
          return;
        }

        annually.quarters.push(quarter);
      };

      for (let quarter = 1; quarter <= 4; ++quarter) {
        if (year === this.yearFrom && quarter >= this.quartersFrom) {
          if (this.yearFrom !== this.yearTo) {
            append(quarter);
          } else {
            if (quarter < this.quartersTo) {
              append(quarter);
            }
          }
        }

        if (year === this.yearTo && quarter < this.quartersTo) {
          if (this.yearFrom !== this.yearTo) {
            append(quarter);
          } else {
            if (quarter >= this.quartersFrom) {
              append(quarter);
            }
          }
        }

        if (year !== this.yearFrom && year !== this.yearTo) {
          append(quarter);
        }
      }

      this.list.push(annually);
    }
  }

  render() {
    const keys = {
      1: "I եռամսյակ",
      2: "II եռամսյակ",
      3: "III եռամսյակ",
      4: "IV եռամսյակ",
    };

    return (
      <Container>
        <div className="top-area">
          <BigTitle title="Հաշվետվություն" />
        </div>

        <div className="body-area">
          <Container>
            {this.list.map((item, yearIndex) => (
              <div key={`year_${yearIndex}`} className="year-row">
                <div className="year-row-title">{item.year}</div>
                <div className="year-row-quarters">
                  {item.quarters.map((quarter, quarterIndex) => (
                    <Link
                      key={`quarter_${quarterIndex}`}
                      className="quarter-row"
                      to={`/documents/facenet_report_${item.year}_${quarter}.pdf`}
                      target="_blank"
                    >
                      {keys[quarter]}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </Container>
        </div>
      </Container>
    );
  }
}

export default Reporting;
