import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./HeaderIntro.scss";

import {
  CircularButton,
  Image,
  PurpleTitle,
  BigTitle,
} from "../../../components";
import HeaderNavigation from "../../../layout/HeaderNavigation";
import IntroRightImage from "../../../assets/images/site/home-page/hero.png";

class HeaderIntro extends Component {
  state = {
    gotoPricing: false,
  };

  constructor(props) {
    super(props);

    this.onGoToPricingClick = this.onGoToPricingClick.bind(this);
  }

  onGoToPricingClick(event) {
    this.setState({ gotoPricing: true });
  }

  render() {
    if (this.state.gotoPricing) {
      return <Redirect to="/pricing" />;
    }

    return (
      <div className="header-intro">
        <HeaderNavigation />

        <Container>
          <Row className="align-items-center">
            <Col md={6} sm={12}>
              <div className="entry">
                <PurpleTitle title="Wooowww" />
                <BigTitle title="Ցածր գին" />

                <p>
                  Գերարագ ինտերնետ սկսած ամսեկան ընդամենը 4000 դրամից։
                  <br />
                  Շտապիր միանալ մեզ։
                </p>

                <div className="button-space">
                  <CircularButton
                    primary={true}
                    onClick={this.onGoToPricingClick}
                    text="Գնացուցակ"
                  />
                </div>
              </div>
            </Col>

            <Col md={6} sm={12}>
              <div className="entry">
                <Image className="hero-image" source={IntroRightImage} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HeaderIntro;
