import React, { Component } from "react";
import "./BigTitle.scss";

class BigTitle extends Component {
  render() {
    const { title } = this.props;

    return <h2 className="big-title">{title}</h2>;
  }
}

export default BigTitle;
