import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./Pricing.scss";

import { BigTitle, PurpleTitle } from "../../components";
import PricingItem from "./PricingItem";
import configs from "../../configs";

class Pricing extends Component {
  static pageClassName = "pricing";

  render() {
    const colSizes = {
      lg: {
        size: 4,
        offset: 1,
      },
      md: {
        size: 6,
        offset: 0,
      },
      sm: {
        size: 8,
        offset: 2,
      },
      xs: {
        size: 12,
        offset: 0,
      },
    };

    return (
      <Container>
        <div className="top-area">
          <PurpleTitle title="Ընտրիր քո փաթեթը" />
          <BigTitle title="Գնացուցակ" />
        </div>

        <div className="body-area">
          <Container>
            <Row>
              {configs.packages.map((pkg) => (
                <Col key={pkg.plan} {...colSizes}>
                  <PricingItem {...pkg} />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </Container>
    );
  }
}

export default Pricing;
