import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";

const root = document.getElementById("root");
setTimeout(() => {
  root.innerHTML = "";

  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    root
  );
}, 500);
