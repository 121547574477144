import React, { Component } from "react";
import "./CircularButton.scss";

class CircularButton extends Component {
  render() {
    const props = { ...this.props };
    props.className = `${props.className || ""} circular-button`;

    if (props.primary) {
      props.className += " circular-button-primary";
    }

    delete props.primary;
    return <button {...props}>{props.text}</button>;
  }
}

export default CircularButton;
