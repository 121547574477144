import React, { Component } from "react";
import "./PageContent.scss";

class PageContent extends Component {
  render() {
    const PageComponent = this.props.component;

    return (
      <div className={`page-content ${PageComponent.pageClassName}`}>
        <PageComponent {...this.props.props} />
      </div>
    );
  }
}

export default PageContent;
