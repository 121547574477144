const configs = {
  packages: [
    {
      plan: "minimal",
      supports: ["personal", "connection"],
    },
    {
      plan: "medium",
      supports: ["personal", "connection", "wifi"],
    },
    {
      plan: "large",
      supports: ["personal", "connection", "wifi"],
    },
    {
      plan: "corporate",
      supports: ["personal", "connection"],
    },
  ],
};

export default configs;
