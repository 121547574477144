import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./AboutArea.scss";

import { Image, BigTitle } from "../../../components";
import AboutLeftImage from "../../../assets/images/site/home-page/about-us.png";

class AboutArea extends Component {
  render() {
    return (
      <div className="about-area">
        <Container>
          <Row className="align-items-center">
            <Col md={6} sm={12}>
              <div className="entry">
                <Image className="about-image" source={AboutLeftImage} />
              </div>
            </Col>

            <Col md={6} sm={12}>
              <div className="entry">
                <BigTitle title="Մանրամասներ" />

                <p>
                  Առայժմ մեզ կարող են միանալ Արագածոտնի մարզի Ալագյազ և Մելիք
                  գյուղերի բնակիչները։
                </p>

                {/* <div className="button-space">
                  <CircularButton text="View pricing" primary={true} />
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AboutArea;
