import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./PricingArea.scss";

import { BigTitle, PurpleTitle } from "../../../components";
import PricingItem from "./PricingItem";
import configs from "../../../configs";

class PricingArea extends Component {
  render() {
    const colSizes = {
      lg: {
        size: 3,
        offset: 0,
      },
      md: {
        size: 6,
        offset: 0,
      },
      sm: {
        size: 8,
        offset: 2,
      },
      xs: {
        size: 12,
        offset: 0,
      },
    };

    return (
      <div className="home-pricing">
        <Container>
          <PurpleTitle title="Գնացուցակ" />
          <BigTitle title="Ընտրիր քո փաթեթը" />
        </Container>

        <Container>
          <Row>
            {configs.packages.map((pkg) => (
              <Col key={pkg.plan} {...colSizes}>
                <PricingItem {...pkg} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}

export default PricingArea;
