import React, { Component } from "react";
import "./Image.scss";

class Image extends Component {
  render() {
    const props = { ...this.props };
    props.className = `${props.className || ""} image`;

    props.draggable = false;
    props.src = props.source;

    delete props.source;
    delete props.alt;

    return <img {...props} alt="facenet" />;
  }
}

export default Image;
