const pricing = {
  minimal: {
    icon: "fas fa-biking",
    price: 4000,
    name: "Facenet 4000",
    speed: 10,
  },
  medium: {
    icon: "fas fa-star",
    price: 6000,
    name: "Facenet 6000",
    speed: 15,
  },
  large: {
    icon: "fas fa-rocket",
    price: 8000,
    name: "Facenet 8000",
    speed: 20,
  },
  corporate: {
    icon: "fas fa-university",
    price: 1000,
    name: "Facenet Corporate",
    speed: 1,
  },
};

export default pricing;
