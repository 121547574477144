import React, { Component } from "react";
import "./SiteLayout.scss";

import PageHeader from "../PageHeader";
import PageFooter from "../PageFooter";
import PageContent from "../PageContent";

class SiteLayout extends Component {
  render() {
    const { withHeader, props, component } = this.props;

    return (
      <div>
        {withHeader && <PageHeader component={component} />}
        <PageContent component={component} props={props} />
        <PageFooter />
      </div>
    );
  }
}

export default SiteLayout;
