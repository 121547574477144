import React, { Component, Fragment } from "react";
import "./HomePage.scss";

import HeaderIntro from "./HeaderIntro";
import ServiceArea from "./ServiceArea";
import AboutArea from "./AboutArea";
import JoinUsArea from "./JoinUsArea";
import PricingArea from "./PricingArea";

class HomePage extends Component {
  static pageClassName = "home-page";

  render() {
    return (
      <Fragment>
        <HeaderIntro />
        <ServiceArea />
        <AboutArea />
        <JoinUsArea />
        <PricingArea />
      </Fragment>
    );
  }
}

export default HomePage;
