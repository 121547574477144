import React, { Component } from "react";
import "./PageFooter.scss";

class PageFooter extends Component {
  render() {
    return (
      <div className="footer-page">
        <div className="footer-icon" />
        <h2>Facenet</h2>
        <h6>© Բոլոր իրավունքները պաշտպանված են։</h6>
      </div>
    );
  }
}

export default PageFooter;
