import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import SiteLayout from "./layout";
import "./assets/sass/App.scss";

import HomePage from "./pages/HomePage";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";
import Reporting from "./pages/Reporting";

class App extends Component {
  constructor(props) {
    super(props);

    this.page = this.page.bind(this);
    this.route = this.route.bind(this);
  }

  page(component, withHeader) {
    return (props) => (
      <SiteLayout withHeader={withHeader} props={props} component={component} />
    );
  }

  route(url, component, withHeader = true) {
    return (
      <Route
        render={this.page(component, withHeader)}
        exact={true}
        path={url}
      />
    );
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Redirect exact={true} from="/" to="/home-page" />

          {this.route("/home-page", HomePage, false)}
          {this.route("/pricing", Pricing)}
          {this.route("/contact-us", ContactUs)}
          {this.route("/reporting", Reporting)}
          {this.route("*", NotFound)}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
