import React, { Component } from "react";
import "./PageHeader.scss";

import HeaderNavigation from "../HeaderNavigation";

class PageHeader extends Component {
  render() {
    const { component } = this.props;

    return (
      <div className="header-pages">
        <HeaderNavigation />

        {component.title && (
          <div className="pages-title">
            <div className="container">
              <span>We Are Professional</span>
              <h2>{component.title}</h2>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PageHeader;
