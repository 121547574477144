import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import GoogleMap from "google-map-react";
import "./ContactUs.scss";

import ContactInfoCard from "./ContactInfoCard";

class ContactUs extends Component {
  static pageClassName = "contact-us";

  googleMapStyle = [
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels",
      stylers: [
        {
          hue: "#548096",
        },
        {
          saturation: -50,
        },
        {
          lightness: 35,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          hue: "#548096",
        },
        {
          saturation: -37,
        },
        {
          lightness: -10,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          hue: "#e3cbac",
        },
        {
          saturation: 30,
        },
        {
          lightness: -7,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          saturation: -49,
        },
        {
          lightness: 5,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          saturation: -90,
        },
        {
          lightness: 90,
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [
        {
          visibility: "off",
        },
        {
          lightness: 25,
        },
      ],
    },
  ];

  coordinates = {
    lat: 40.190643,
    lng: 44.546691,
  };

  render() {
    return (
      <Fragment>
        <div className="contact-info">
          <Container>
            <Row>
              <Col lg={4} md={12}>
                <ContactInfoCard type="address">
                  Նորք-Մարաշ, Նորքի 2-րդ փող., տուն 4, Երևան, ՀՀ
                </ContactInfoCard>
              </Col>
              <Col lg={4} md={6}>
                <ContactInfoCard type="phone">
                  <a href="tel:+37499370773">+37499370773</a>
                </ContactInfoCard>
              </Col>
              <Col lg={4} md={6}>
                <ContactInfoCard type="email">
                  <a href="mailto:face_net@mail.ru">face_net@mail.ru</a>
                </ContactInfoCard>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="contact-map">
          <Container>
            <div className="contact-map-container">
              <GoogleMap
                bootstrapURLKeys={{
                  key: "AIzaSyAqe05t0MwtNQ3jHjGUqk-avvQbf1vBVEA",
                }}
                defaultCenter={this.coordinates}
                defaultZoom={17.5}
                options={{
                  draggable: true,
                  panControl: false,
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
                  zoomControl: false,
                  styles: this.googleMapStyle,
                }}
              >
                <div
                  className="map-marker"
                  lat={this.coordinates.lat}
                  lng={this.coordinates.lng}
                >
                  <div className="pin" />
                  <div className="pulse" />
                </div>
              </GoogleMap>
            </div>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default ContactUs;
