import React, { Component } from "react";
import "./PurpleTitle.scss";

class PurpleTitle extends Component {
  render() {
    const { title } = this.props;

    return (
      <span className="purple-tite">{title}</span>
    );
  }
}

export default PurpleTitle;
