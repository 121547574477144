import React, { Component } from "react";
import priceing from "../../../../priceing";
import "./PricingItem.scss";

class PricingItem extends Component {
  supports = {
    personal: "Ֆիզիկական անձանց համար",
    corporate: "Կորպորատիվ անձանց համար",
    connection: "Անվճար միացում",
    wifi: "Wi-Fi 5 ամիս կանխավճարի դեպքում",
  };

  constructor(props) {
    super(props);

    this.numberFormat = this.numberFormat.bind(this);
    this.getSpeed = this.getSpeed.bind(this);
  }

  numberFormat(value, char = ",") {
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, char);
  }

  getSpeed(planItem) {
    if (Array.isArray(planItem.speed)) {
      return `${planItem.speed[0]}-${planItem.speed[1]}`;
    }

    return planItem.speed.toString();
  }

  render() {
    const { plan, supports } = this.props;
    const planItem = priceing[plan];

    return (
      <div className="pricing-item">
        <div className="card-icon">
          <i className={planItem.icon} />
        </div>
        <div className="card-plan">{planItem.name}</div>
        <div className="card-price">
          {this.numberFormat(planItem.price, " ")} ֏/ամիս
        </div>
        <div className="card-supports">
          <ul>
            <li>{this.getSpeed(planItem)} ՄԲ/վ արագություն</li>
            {supports.map((support) => (
              <li key={`${plan}_${support}`}>{this.supports[support]}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default PricingItem;
