import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./ServiceArea.scss";

import ServiceItem from "./ServiceItem";

class ServiceArea extends Component {
  render() {
    return (
      <div className="services-area">
        <Container>
          <Row>
            <Col lg={4} md={6} sm={12}>
              <ServiceItem
                type="user"
                title="Բոլորի համար"
                text="Ֆիզիկական և կորպորատիվ անձանց համար։"
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <ServiceItem
                type="price"
                title="Ցածր գին"
                text="Ցածր գին, սկսած ամսեկան ընդամենը 4000 դրամից։"
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <ServiceItem
                type="connect"
                title="Անվճար միացում"
                text="Անվճար միացում։"
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <ServiceItem
                type="wifi"
                title="Wi-Fi"
                text="Wi-Fi 5 ամիս կանխավճարի դեպքում։"
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <ServiceItem
                type="stable"
                title="Միշտ հասանելի"
                text="Մեզ հետ միասին Դուք միշտ ունեք հասանելի ինտերնետ։"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ServiceArea;
