import React, { Component } from "react";
import "./ContactInfoCard.scss";

class ContactInfoCard extends Component {
  render() {
    const types = {
      address: {
        text: "Հասցե",
        icon: "fas fa-map-marked-alt",
        // icon: "fas fa-map-marker-alt",
        // icon: "fas fa-location-arrow",
      },
      phone: {
        text: "Հեռախոս",
        icon: "fas fa-phone-alt",
      },
      email: {
        text: "Էլ-հասցե",
        icon: "fas fa-envelope-open",
        // icon: "fas fa-envelope",
        // icon: "fas fa-at",
        // icon: "fas fa-paper-plane",
      },
    };

    const { children, type } = this.props;
    const item = types[type];

    return (
      <div className="contact-info-card">
        <div className="card-icon">
          <div className="icon">
            <i className={item.icon} />
          </div>
          <div className="type">{item.text}</div>
        </div>

        <div className="card-text">{children}</div>
      </div>
    );
  }
}

export default ContactInfoCard;
