import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import "./NotFound.scss";

import { PurpleTitle, BigTitle, CircularButton, Image } from "../../components";
import NotFoundImage from "../../assets/images/site/not-found/404.png";

class NotFound extends Component {
  static pageClassName = "error-404";

  state = {
    goToHome: false,
  };

  constructor(props) {
    super(props);

    this.onGoToHomeClick = this.onGoToHomeClick.bind(this);
  }

  onGoToHomeClick(event) {
    this.setState({ goToHome: true });
  }

  render() {
    if (this.state.goToHome) {
      return <Redirect to="/" />;
    }

    return (
      <Fragment>
        <div className="top-area">
          <Container>
            <PurpleTitle title="Օ ոչ" />
            <BigTitle title="Էջը չի գտնվել" />
          </Container>
        </div>

        <div className="body-area">
          <Container>
            <Image source={NotFoundImage} />
          </Container>
        </div>

        <div className="bottom-area">
          <Container>
            <p>
              Տվյալ հղումը գոյություն չունի կամ էջը տեղափոխվել է մեկ այլ
              հղումով:
            </p>

            <CircularButton
              text="Գնալ դեպի գլխավոր էջ"
              onClick={this.onGoToHomeClick}
            />
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default NotFound;
